<template>
  <div :class="classes" @click="handler">
    <div class="show-remaining__title">
      <slot></slot>
    </div>
    <span class="chevron" v-html="getIcon('caret')"></span>
  </div>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    isShowRemaining: {
      type: Boolean,
      required: true
    }
  },
  name: 'TableShowRemainingButton',
  methods: {
    handler() {
      this.$emit('toggleRemaining')
    }
  },
  computed: {
    classes() {
      return {
        'show-remaining': true,
        'show-remaining--active': this.isShowRemaining
      }
    }
  }
}
</script>

<style scoped lang="sass">
.show-remaining
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  cursor: pointer
  max-width: 100vw
  position: sticky
  left: 0
  @extend .common__result-footer
  &__title
    @extend .common__result-footer--count
    color: $main
  .chevron
    @extend .common__result-footer--arrow
    display: flex
  &--active
    .chevron
      transform: rotate(180deg)
</style>
