<script>
import IconMixin from '@/components/mixin/IconMixin.vue'
import Switcher from '@/components/dump/sector_fasteners/Switcher.vue'

export default {
  name: 'Toggler',
  components: { Switcher },
  props: {
    switchItems: {
      type: Array,
      required: false
    },
    selected: {
      type: String,
      required: true
    }
  },
  mixins: [IconMixin],
  data: () => ({
    isShowed: false,
    checked: null
  }),
  mounted() {
    this.checked = this.selected
  },
  methods: {
    toggle() {
      this.isShowed = !this.isShowed
    },
    hide() {
      this.isShowed = false
    },
    update(evt) {
      if (this.isCheckboxes) {
        this.checked = this.checked === evt ? null : evt
      }
      this.$emit(
        'updateToggledValue',
        { value: this.isCheckboxes
          ? this.checked
          : this.switchItems.find((item, index) => Boolean(index) === evt).name
        }
      )
    }
  },
  computed: {
    isCheckboxes() {
      return this.switchItems?.length > 2
    }
  }
}
</script>

<template>
  <article class="toggler" v-click-outside="hide">
    <span v-html="getIcon('plugin')" class="icon" @click="toggle"></span>
    <div v-if="isShowed" class="toggler__container">
      <div class="title">
        <h5 class="title__text">{{ $t('message.result.materialsComputation.defineUnit') }}</h5>
        <button @click="toggle" class="title__button-close">
          <span v-html="getIcon('close')" class="icon"></span>
        </button>
      </div>
      <div class="switcher">
        <ul v-if="isCheckboxes" class="switcher__items" :class="{ 'switcher--checkbox-type': isCheckboxes }">
          <li
            v-for="item of switchItems"
            :key="item.name"
            class="switcher__item"
          >
            <input
              type="checkbox"
              :id="`unit_${item.name}`"
              :checked="item.name === checked"
              :value="item.name"
              @change="update(item.name)"
            />
            <label :for="`unit_${item.name}`">{{ item.label }}</label>
          </li>
        </ul>
        <ul v-else class="switcher__items">
          <li
            v-for="item of switchItems"
            :key="item.name"
            class="switcher__item"
          >{{ item.label }}
          </li>
        </ul>
        <Switcher
          v-if="!isCheckboxes"
          :calculation-need="false"
          @updateSwitchingValue="update($event)"
        />
      </div>
    </div>
  </article>
</template>

<style scoped lang="sass">
.toggler
  position: relative
  margin: 0 auto 0 rem(15)
  .icon
    display: flex
    align-items: center
    cursor: pointer
  .title
    display: flex
    align-items: center
    margin: 0 0 rem(20)
    &__text
      margin: 0
      text-align: left
    &__button-close
      border: 0
      background: none
      cursor: pointer
  &__container
    min-width: 250px
    padding: rem(15) rem(15) rem(20)
    position: absolute
    top: 50%
    right: rem(-5)
    transform: translate(100%, -50%)
    background: $white
    border: 1px solid var(--grey-stroke)
    border-radius: rem(4)
  .switcher
    position: relative
    &__items
      display: flex
      justify-content: space-between
      margin: 0
      padding: 0
      list-style: none
    &__item
      padding: 0 rem(10)
    .switcher
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
    &--checkbox-type
      label
        display: flex
        align-items: center
        position: relative
        padding-left: rem(35)
      label:before, label:after
        position: absolute
        top: 50%
        left: 0
        transform: translate(0, -50%)
        content: ''
        display: flex
        border-radius: 50%
      label:before
        width: rem(26)
        height: rem(26)
        border: 1px solid $red
        background: $white
      label:after
        width: rem(14)
        height: rem(14)
        left: rem(6)
        background: $red
        opacity: 0
        transition: .2s
      input[type="checkbox"]
        opacity: 0
        &:checked + label:after
          opacity: 1
</style>
