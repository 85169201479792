<template>
  <div class="prefooter" ref="prefooter">
    <div class="prefooter__buttons">
      <a
        class="prefooter__buttons-btn prefooter__buttons-btn--save"
        :title="$t('message.save.toProfile')"
        @click="toCalculationLink()"
      >
        <img class="img" src="@/assets/img/cloud.svg" alt="save" />
        <p class="content">
          <span class="content__title">{{ $t('message.preFooter.save') }}</span>
          <span class="content__text">
            {{ $t('message.preFooter.saveText') }}
          </span>
        </p>
      </a>
      <div @click="savePdf" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <svg width="22" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.857 1v5.778c0 .383.15.75.419 1.021.268.271.63.423 1.01.423H21M13.857 1h-10c-.758 0-1.484.304-2.02.846A2.905 2.905 0 001 3.89V24.11c0 .766.301 1.501.837 2.043a2.842 2.842 0 002.02.846h14.286c.758 0 1.484-.304 2.02-.846A2.905 2.905 0 0021 24.11V8.222M13.857 1L21 8.222m-10 4.334v8.666m0 0L6.714 16.89M11 21.222l4.286-4.333"
            stroke="var(--main-color)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">
          <i18n path="message.preFooter.download">
            <template #break>
              <br />
            </template>
            <template #file>
              <b>{{ $t('message.preFooter.pdf') }}</b>
            </template>
          </i18n>
        </div>
      </div>
      <div @click="saveXls" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <svg width="22" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.857 1v5.778c0 .383.15.75.419 1.021.268.271.63.423 1.01.423H21M13.857 1h-10c-.758 0-1.484.304-2.02.846A2.905 2.905 0 001 3.89V24.11c0 .766.301 1.501.837 2.043a2.842 2.842 0 002.02.846h14.286c.758 0 1.484-.304 2.02-.846A2.905 2.905 0 0021 24.11V8.222M13.857 1L21 8.222m-10 4.334v8.666m0 0L6.714 16.89M11 21.222l4.286-4.333"
            stroke="var(--main-color)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">
          <i18n path="message.preFooter.download">
            <template #break>
              <br />
            </template>
            <template #file>
              <b>{{ $t('message.preFooter.xls') }}</b>
            </template>
          </i18n>
        </div>
      </div>
      <div @click="print" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.222 21.222h2.89A2.889 2.889 0 0027 18.333v-5.777a2.89 2.89 0 00-2.889-2.89H3.89A2.889 2.889 0 001 12.557v5.777a2.889 2.889 0 002.889 2.89h2.889M21.222 9.666V3.889A2.889 2.889 0 0018.333 1H9.667a2.889 2.889 0 00-2.89 2.889v5.778m2.89 5.777h8.666a2.889 2.889 0 012.89 2.89v5.777A2.889 2.889 0 0118.332 27H9.667a2.889 2.889 0 01-2.89-2.889v-5.778a2.889 2.889 0 012.89-2.889z"
            stroke="var(--main-color)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">
          <i18n path="message.preFooter.print">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
      </div>
      <div
        @click="toggleConfirmationModal"
        class="prefooter__buttons-btn prefooter__buttons-btn--new"
      >
        <img src="@/assets/img/prefooter--new-calculation.svg" alt="new" />
        <div class="title">
          <b>
            <i18n path="message.preFooter.new">
              <template #break>
                <br />
              </template>
            </i18n>
          </b>
        </div>
      </div>
      <confirmation-modal :is-modal-open="isModalOpen" @close="toggleConfirmationModal">
        <template #text> {{ $t('message.preFooter.newConfirmation.text') }} </template>
        <template #cancel>
          <button v-if="!saveCalculationLink" class="btn btn--red" @click="toCalculationLink()">
            <img src="@/assets/img/cloud--mini.svg" alt="cloud" class="icon" />
            {{ $t('message.preFooter.newConfirmation.confirm') }}
          </button>
        </template>
        <template #confirm>
          <button class="btn btn--green" @click="startNewCalculation">
            <img src="@/assets/img/new-calculation.svg" alt="new" class="icon" />
            {{ $t('message.preFooter.newConfirmation.cancel') }}
          </button>
        </template>
      </confirmation-modal>
    </div>
  </div>
</template>

<script>
import ConfirmationModal from '@/components/dump/ConfirmationModal'
import { mapActions, mapState } from 'vuex'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  data: () => ({
    isModalOpen: false
  }),
  methods: {
    ...mapActions({
      startNewCalculation: 'startNewCalculation'
    }),
    toggleConfirmationModal() {
      this.isModalOpen = !this.isModalOpen
    },
    print() {
      sendStatistic(statisticLabels.prefooter.print)
      window.print()
    },
    savePdf() {
      sendStatistic(statisticLabels.prefooter.pdf)
      this.$emit('savePdf')
    },
    saveXls() {
      sendStatistic(statisticLabels.prefooter.xls)
      this.$emit('saveXls')
    },
    toCalculationLink() {
      if (!this.saveCalculationLink) {
        window.parent.postMessage({ resultId: this.userInfo.result?.id }, '*')
        window.top.location = this.userInfo.result?.link
      } else {
        window.parent.postMessage({ scrollTop: true }, '*')
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: this.$t('message.save.noNeedSaving')
        })
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      saveCalculationLink: state => state.saveCalculationLink
    })
  },
  components: { ConfirmationModal },
  beforeDestroy() {
    this.$root.onBodyScroll()
  }
}
</script>

<style lang="sass" scoped>
.prefooter
  background: $white

  &__buttons
    display: grid
    $t-xl: 20fr repeat(4, 9fr)
    $t-l: repeat(3, 1fr)
    $t-m: repeat(4, 1fr)
    $t-s: 1fr 1fr
    +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
    max-width: rem(1120)
    width: 100%
    +media((margin: (320: rem(48) auto 0, 960: rem(68) auto 0)))
    &-btn
      min-height: rem(153)
      &:not(:last-child)
        +media((box-shadow: (768: inset rem(-1) 0 0 $ec)))
      &--download, &--new
        color: $black
        background: $white

        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        transition: .5s
        cursor: pointer
        text-decoration: none

        .title
          font-weight: normal
          font-size: rem(14)
          line-height: 130%
          text-align: center

        svg
          path
            transition: .5s
        @media(any-hover: hover)
          &:hover
            background: $main
            color: $white
            svg
              path
                stroke: $white
      &--download
        .title
          margin-top: rem(26)
      &--new
        +media((grid-area: (320: '2 / 2 / 3 / 3', 576: '1 / 4 / 2 / 5', 768: '2 / 1 / 3 / 2', 960: auto)))
        .title
          margin-top: rem(14)
      &--save
        +media((grid-column: (320: '1 / 3', 576: '1 / 5', 768: '2 / 4', 960: auto)))
        +media((grid-row-start: (0: 3, 576: 2, 960: 1)))
        +media((padding: (320: 0 rem(25), 768: 0 rem(40) 0 0)))
        background: $main
        display: flex
        flex-direction: row
        align-items: center
        text-decoration: none
        transition: .5s
        position: relative
        cursor: pointer
        box-shadow: none
        @media(any-hover: hover)
          &:hover
            background: $main
            opacity: $hover-opacity
            &:before
              background: $main
              opacity: $hover-opacity
        .img
          pointer-events: none
          +media((padding: (320: 0 rem(24) 0 0, 768: 0 rem(38), 960: 0 rem(18), 1120: 0 rem(38))))
        .content
          display: flex
          flex-flow: row wrap
          margin: 0
          color: $white
          text-align: left
          pointer-events: none
          &__title
            width: 100%
            font-style: normal
            font-weight: bold
            margin-bottom: rem(12)
            +media((font-size: (320: rem(20), 960: rem(18))))
            +media((line-height: (320: rem(24), 960: rem(22))))
          &__text
            font-style: normal
            font-weight: 600
            font-size: rem(12)
            line-height: 150%
</style>
