<template>
  <div :class="wrapperClasses" class="unprint">
    <div class="addons__title">
      <slot> {{ $t('message.addons.title') }} </slot>
    </div>
    <div class="addons__items">
      <a :href="item.link" v-for="(item, i) in items" :key="i" class="link" target="_blank">
        <slot name="icon">
          <span v-html="getIcon('download')" class="link__icon"></span>
        </slot>
        {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isPlugins: {
      type: Boolean
    }
  },
  name: 'addons',
  computed: {
    wrapperClasses() {
      return {
        addons: true,
        plugins: this.isPlugins
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.addons
  $w-s: calc(100% - 48px)
  $w-l: rem(960)
  +media((max-width: (0: $w-s, 992: $w-l)))
  +media((margin: (0: rem(32) auto, 768: rem(51) auto rem(34))))
  &.plugins
    .addons
      &__items
        +media((grid-template-columns: (320: 1fr, 576: repeat(2, 1fr), 992: repeat(3, 1fr))))
  &__title
    @extend %18
    font-weight: bold
    text-align: left
    +media((margin-bottom: (0: rem(15), 960: rem(34))))
  &__items
    display: grid
    width: 100%
    +media((margin: (320: 0 auto, 960: 0)))
    +media((grid-template-columns: (320: 1fr, 992: repeat(2, 1fr))))
    .link
      background: $semi-white
      border: rem(1) solid $ec
      box-sizing: border-box
      cursor: pointer
      display: flex
      flex-direction: row
      align-items: center
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: rem(22)
      color: $black
      transition: .5s
      text-decoration: none
      text-align: left
      padding: 0 rem(28)
      min-height: rem(68)
      width: 100%
      &__icon
        display: flex
        align-items: center
        padding-right: rem(28)
        transition: .5s
      @media (any-hover: hover)

        &:hover
          color: $main
          .link__icon
            opacity: .7
</style>
