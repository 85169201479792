<script>
export default {
  name: 'CalculationNotification'
}
</script>

<template>
  <article class="notification">
    <p class="notification__text">
      {{ $t('message.notification.withoutIsolation') }}
    </p>
  </article>
</template>

<style scoped lang="sass">
.notification
  $w-s: calc(100% - 48px)
  $w-l: rem(960)
  +media((max-width: (0: $w-s, 992: $w-l)))
  +media((margin: (0: rem(32) auto, 768: rem(51) auto rem(34))))
  color: $red
  text-align: left
  border: 1px solid red
  &__text
    margin: 0
    +media((padding: (0: rem(12), 480: rem(12) rem(20), 992: rem(17) rem(25))))
    +media((font-size: (320: rem(14), 960: rem(16))))
</style>
