<template>
  <div class="container unprint">
    <div class="nav-header">
      <div class="slopePage__title" :class="{ details__title: isDetails }">
        <b>{{ isDetails ? $t('message.result.nav.detailsTitle') : $t('message.result.nav.title') }}</b>
        <tippy
          interactive
          placement="top"
          animation="shift-toward"
          arrow="false"
          :maxWidth="240"
          v-if="!isDetails"
        >
          <template #trigger>
            <div class="title-tooltip">
              <span v-html="getIcon('tooltip')"></span>
            </div>
          </template>
          <div class="simple-tooltip__text">{{ $t('message.result.nav.info') }}</div>
        </tippy>
      </div>
      <simple-btn color="red" @btnClicked="isEditable = !isEditable" v-if="!isDetails">
        <template #icon>
          <span class="icon">
            <img :src="editBtnKit.src" :alt="editBtnKit.text" />
          </span>
        </template>
        {{ editBtnKit.text }}
      </simple-btn>
    </div>
    <div class="slopePage__links">
      <a
        @click="editSector(i)"
        class="slopePage__links-item"
        :class="{ 'slopePage__links-item--editable': isEditable }"
        v-for="(sector, i) in allSlopes"
        :key="sector.uid"
      >
        <span v-if="isEditable" class="icon" v-html="getIcon('pencil')"></span>
        <div class="content">
          <div class="content__img">
            <component
              :is="getCurrentFigureComponent(sector.slopeForm)"
              :navId="i"
              :slopeParams="sector.slopeParams"
              :defaultSvgParams="defaultSvgParams"
              :isNav="true"
            >
            </component>
          </div>
          <div class="content__info">
            <div class="content__info-number">{{ getSectorIndex(sector.uid) }}</div>
            <div class="content__info-count">x{{ sector.slopeCount }}</div>
          </div>
        </div>
        <div class="overlay" v-if="!isEditable">
          <b>{{ $t('message.result.nav.watch') }}</b> {{ sector.name }}
        </div>
      </a>
      <button class="slopePage__links-add" v-if="!isDetails" @click="addFromResult">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="#2ECC71"></circle>
          <path
            d="M12 18V6M18 12H6"
            stroke="#fff"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linejoin="round"
          ></path>
        </svg>
        <span class="slopePage__links-add_hover">{{ $t('message.result.nav.newArea') }}</span>
      </button>
      <router-link :to="{ name: 'Result' }" class="slopePage__links-result" v-if="isDetails">
        <svg
          width="26"
          height="29"
          viewBox="0 0 26 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 13.6667V3.66667C24 2.75 23.2929 2 22.4286 2H3.57143C2.70714 2 2 2.75 2
                      3.66667V13.6667M24 13.6667H2M24 13.6667L23.9999 25.3333C23.9999 26.2499
                      23.2928 26.9999 22.4285 26.9999L16.143 27M24 13.6667L16.143 13.6666M2
                      13.6667L9.07143 13.6666M2 13.6667V20.3333M9.07143 13.6666V20.3333M9.07143
                      13.6666H16.143M9.07143 20.3333H2M9.07143 20.3333V27M9.07143 20.3333H16.143M2
                      20.3333V25.3333C2 26.25 2.70714 27 3.57143 27H9.07143M9.07143 27H16.143M16.143
                      13.6666V20.3333M16.143 13.6666V27M16.143 20.3333V27"
            stroke="var(--main-color)"
            stroke-width="3"
            stroke-miterlimit="22.9256"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        <span>
          <i18n :path="'message.result.nav.backToResult'">
            <template #break>
              <br />
            </template>
          </i18n>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defaultSvgParams, formsComponents } from '@/utils/drawing'
import SimpleBtn from '@/components/dump/simpleBtn'
import IconMixin from '@/components/mixin/IconMixin'
import { mapActions } from 'vuex'

export default {
  mixins: [IconMixin],
  props: {
    isDetails: Boolean
  },
  data: () => ({
    isEditable: false
  }),
  components: {
    SimpleBtn
  },
  methods: {
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults',
      resetUnit: 'resetUnit'
    }),
    getSectorIndex(uid) {
      const index = this.allSlopes?.findIndex(s => s.uid === uid) + 1
      return index <= 9 ? `0${index}` : index
    },
    getCurrentFigureComponent(slopeForm) {
      return formsComponents[slopeForm]
    },
    addFromResult() {
      this.resetSaveCalculationResult()
      this.resetUnit({ index: null })
      this.allSlopes.forEach((slope, index) => this.resetUnit({ index }))
      this.$router.push({ name: 'Wizard', params: { id: parseInt(this.allSlopes.length, 10) } })
      this.$store.commit('addNewSlope', { id: parseInt(this.allSlopes.length, 10) + 1 })
    },
    editSector(index) {
      if (this.isEditable) {
        this.resetSaveCalculationResult()
        this.resetAllUnits(index)
        return this.$router.push({ name: 'Wizard', params: { id: index } })
      }

      if (!(this.isDetails && index === this.slopeId)) {
        return this.$router.push({ name: 'Details', params: { id: index } })
      }
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    },
    resetAllUnits(index) {
      this.resetUnit({ index })
      this.resetUnit({ index: null })
    }
  },
  computed: {
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    defaultSvgParams: () => defaultSvgParams,
    editBtnKit() {
      return this.isEditable
        ? {
          text: this.$t('message.result.nav.cancel'),
          src: require('@/assets/img/edit-btn--cancel.svg')
        }
        : { text: this.$t('message.result.nav.confirm'), src: require('@/assets/img/edit-btn.svg') }
    }
  }
}
</script>
<style lang="sass" scoped>
.nav-header
  display: flex
  +media((align-items: (320: flex-start, 650: center)))
  +media((flex-direction: (320: column, 650: row)))
  +media((justify-content: (320: flex-start, 650: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(17), 992: rem(51) rem(-15) rem(17))))
  +media((padding: (320: 0 rem(25), 992: 0)))
  .slopePage
    &__title
      padding: 0
      +media((margin-bottom: (320: rem(15), 650: 0)))
      display: flex
      align-items: center
      +media((font-size: (320: rem(18), 68: rem(24))))
      .title-tooltip
        margin-left: rem(10)
        align-items: center
        display: flex
</style>
<style lang="sass">
.simple-tooltip__text
  font-weight: 500
  font-size: rem(12)
  line-height: 150%
  padding: rem(11)
  color: $tn_gray
.slopePage
  &__title
    &.details__title
      +media((font-size: (320: rem(18), 768: rem(20), 992: rem(24))))
      +media((padding-top: (320: rem(46), 768: rem(70))))
      +media((padding-bottom: (320: rem(30), 768: rem(36))))
  &__info
    display: flex
    flex-direction: row
    align-items: center
    +media((padding-left: (320: rem(24), 992: 0)))
    +media((margin-bottom: (320: rem(28), 768: rem(37))))
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      color: $black
      padding-left: rem(21)
      text-align: left
      +media((line-height: (320: rem(20), 768: rem(24))))
      +media((padding-right: (320: rem(24), 768: 0)))
  &__links
    display: grid
    grid-column-gap: rem(1)
    grid-row-gap: rem(1)
    +media((grid-template-columns: (320: repeat(2, 1fr), 420: repeat(3, 1fr), 576: repeat(4, 1fr), 660: repeat(5, 1fr), 768: repeat(6, 1fr))))
    +media((margin: (320: 0 rem(9), 992: 0 rem(-15))))
    &-item
      background: $white
      text-decoration: none
      padding: rem(10)
      position: relative
      svg
        max-width: 100%
      .overlay
        padding: rem(20)
        height: 100%
        width: 100%
        position: absolute
        top: 0
        left: 0
        pointer-events: none
        background: $main
        color: $white
        transition: .5s
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(20)
        opacity: 0
        box-sizing: border-box
      &:hover
        .overlay
          opacity: $hover-opacity
      .content
        &__info
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between
          font-style: normal
          font-size: rem(14)
          line-height: 1
          color: $black
          &-number
            font-weight: bold
          &-count
            font-weight: 500
      &--editable
        transition: .5s
        .scheme-circuit
          transition: .5s
        @media (any-hover: hover)
          &:hover
            box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3), 0 rem(5) rem(30) rgba(51, 51, 51, 0.2)
            .scheme-circuit
              stroke: $black
            .icon
              filter: none
        .icon
          display: flex
          justify-content: center
          align-items: center
          height: rem(34)
          width: rem(34)
          position: absolute
          left: 0
          top: 0
          pointer-events: none
          filter: invert(97%) sepia(0%) saturate(1%) hue-rotate(173deg) brightness(89%) contrast(92%)
          svg
            height: 60%
            width: 60%
    &-add
      background: $white
      box-shadow: none
      cursor: pointer
      min-height: rem(100)
      margin: rem(10)
      border: rem(1) dashed $tn_gray
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: relative
      transition: .5s
      overflow: hidden
      outline: none
      &_hover
        position: absolute
        height: 100%
        width: 100%
        top: 0
        left: 0
        color: $white
        background: $green
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: 700
        font-size: rem(12)
        line-height: rem(15)
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        transition: .5s
        opacity: 0
        padding: rem(50)
      &:hover .slopePage__links-add_hover
          opacity: 1
    &-result
      background: $white
      box-shadow: none
      cursor: pointer
      min-height: rem(116)
      margin: rem(10)
      border: rem(1) dashed $tn_gray
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: relative
      transition: .5s
      overflow: hidden
      outline: none
      text-decoration: none
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(20)
      color: $main
      svg
        margin-bottom: rem(14)
        path
          transition: .5s
      &:hover
        background: $main
        color: $white
        font-weight: bold
        svg
          path
            stroke: $white
</style>
